*{
  margin: 0;
  padding: 0;
  font-family: 'PingFang SC';
}
body{
  overflow-y: auto;
  overflow-x: hidden;
}
.body_background{
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  display: flex;
  position: relative;
  justify-content: center;
  
}
.banner{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 36vh;
}
.main{
  width: 1200px;
  position: relative;
}
.input_title{
 
  display: flex;
  position: relative;
  justify-content: center;

  flex-direction: column;
  align-items: center;
  height: 36vh;

}
.title{
  font-family: PingFang SC;
  font-size: 40px;
  font-weight: 500;
  line-height: 5.6vh;
  text-align: left;
  margin-bottom: 2.4vh;

}

.input{
  position: relative;

  /* width: 520px;
  height: 52px;

  gap: 0px;
  border-radius: 45px;
  border: 1px solid #FFFFFF;
  position: relative;
  /* overflow: hidden; */
  
}
.sourch {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 20px;
  top: calc( 50% - 10px );
  

}
.input input {
  width: 475px;
  height: 5.2vh;
 
  color: #3C5180;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 400;
 border-radius: 45px;
 border: 1px solid #FFFFFF;
 padding-left: 45px;
 line-height: 1;
}
.input input::placeholder {
  color: #3C5180;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  margin-left: 45px;  
}

.list{
  margin-top: 6.3vh;
  display: flex;
  flex-wrap: wrap;
}
.list>div{
  flex: 1;
  display: flex;
  justify-content: center;
  max-width: 25%;
  margin-bottom: 2.8vh;

  height: 26.3vh;
}
.list_a{
  border: 1px solid #DDE4ED;

  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  
  width: 273px;
  height: 26.3vh;
  position: relative;
  overflow: hidden;
}


.list_a::before{
  content: "";
  width: 0px;
  height: 5px;
  background-color: #428EFF;
  position: absolute;
  bottom: 0;
  transition-property:all;
  transition-duration: 0.3s;
}
.list_a:hover.list_a::before{
  width: 100%;
  
}
.list_a .list_img{
  margin-top: 3.9vh;
  /* width: 123px; */
  height: 13.8vh;

 
}
.list_tilte_storage{
  margin-top: 2.7vh;
  font-family: PingFang SC;
  font-size: 22px;
  font-weight: 500;
  line-height: 3.08vh;

  color: #3C5180 !important;
  
}
.list_tilte_storage .storage{
  color: #407BFF !important;
}